/** @format */

import { Typography, Box, Card, CardContent } from "@mui/material";
import "../business.css";

import React from "react";
import TopBar from "../components/Navbar";

const ContactUs = ({ navigation }: any) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 850);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const topTextStyle = isMobile
    ? {
        marginTop: 10,
        fontWeight: 600,
        fontSize: 34,
        textAlign: "center",
        fontFamily: "MyHeboFont,Heebo-Bold",
      }
    : {
        marginTop: 5,
        fontWeight: 600,
        fontSize: 54,
        textAlign: "center",
        fontFamily: "MyHeboFont,Heebo-Bold",
      };

  const descriptionTextStyle = isMobile
    ? {
        fontWeight: 600,
        fontSize: 20,
        textAlign: "center",
        fontFamily: "MyCustomFont,CalSans",
      }
    : {
        fontWeight: 600,
        fontSize: 36,
        textAlign: "center",
        fontFamily: "MyCustomFont,CalSans",
      };
  const contentTextStyle = isMobile
    ? {
        fontWeight: 500,
        fontSize: 20,
        textAlign: "center",
        fontFamily: "MyCustomFont,CalSans",
      }
    : {
        fontWeight: 500,
        fontSize: 36,
        textAlign: "center",
        fontFamily: "MyCustomFont,CalSans",
      };

  const cardStyle = {
    margin: 2,
    padding: 2,
    boxShadow: 3,
    borderRadius: 2,
    textAlign: "center",
    width: isMobile ? "90%" : "45%", // Adjust width based on screen size
  };

  return (
    <>
      <TopBar navigation={navigation} />

      {/* Contact Us Title */}
      <Typography sx={topTextStyle}>Contact Us</Typography>

      {/* Sections */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
          padding: 3,
        }}
      >
        {/* Headquarters Section */}
        <Card sx={cardStyle}>
          <CardContent>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 30,
                textAlign: "left",
                fontFamily: "MyCustomFont,CalSans",
                marginBottom: 2,
                textDecoration: "underline",
              }}
            >
              Headquarters
            </Typography>
            <Typography sx={descriptionTextStyle}>
              Reshape Technologies Inc
            </Typography>
            <Typography sx={contentTextStyle}>15 Parish Ave,</Typography>
            <Typography sx={contentTextStyle}>Ajax, ON - L1Z 1N2</Typography>
            <Typography sx={contentTextStyle}>
              Email:{" "}
              <a href="mailto:support@reshapeapp.ai">support@reshapeapp.ai</a>
            </Typography>
          </CardContent>
        </Card>

        {/* Indian Branch Section */}
        <Card sx={cardStyle}>
          <CardContent>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 30,
                textAlign: "left",
                fontFamily: "MyCustomFont,CalSans",
                marginBottom: 2,
                textDecoration: "underline",
              }}
            >
              India Office
            </Typography>
          <Typography
              sx={{
                fontWeight: 500,
                fontSize: 30,
                textAlign: "left",
                fontFamily: "MyCustomFont,CalSans",
                marginBottom: 2,
                textDecoration: "underline",
              }}
            >
              HQ:
            </Typography>
            <Typography sx={descriptionTextStyle}>
              Reshape Technologies Private Limited
            </Typography>
            <Typography sx={contentTextStyle}>
              267, 619, NRI Cir, Sector 26, Pratap Nagar,{" "}
            </Typography>
            <Typography sx={contentTextStyle}>
              Jaipur, Rajasthan 302033
            </Typography>
            <Typography sx={descriptionTextStyle}>
              ---
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 30,
                textAlign: "left",
                fontFamily: "MyCustomFont,CalSans",
                marginBottom: 2,
                textDecoration: "underline",
              }}
            >
              Operational Address:
            </Typography>
            <Typography sx={descriptionTextStyle}>
              Reshape Technologies Private Limited
            </Typography>
            <Typography sx={contentTextStyle}>
              Cabin 20, RAW Coworking Space, Plot no. 8, Govind Marg, Block-B, Malviya Nagar,{" "}
            </Typography>
            <Typography sx={contentTextStyle}>
              Jaipur, Rajasthan 302017
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center-align content
                alignItems: "center", // Vertically align items
                gap: 2, // Add spacing between items
                flexWrap: "wrap", // Allow wrapping on smaller screens
              }}
            >
      

              {/* Email */}
              <Typography sx={contentTextStyle}>
                Email:{" "}
                <a
                  href="mailto:dewanshu@reshapeapp.ai"
                  style={{ textDecoration: "none" }}
                >
                  dewanshu@reshapeapp.ai
                </a>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: 33,
          backgroundColor: "#1067bf",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <Typography>
          © All rights reserved | Reshape Technologies Inc
        </Typography>
      </Box>
    </>
  );
};

export default ContactUs;
